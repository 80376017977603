import React from 'react';

import './styles.scss';
import classNames from 'classnames';

import playPause from '../../utils/playPause';

import getUniqueId from '../../utils/getUniqueId';

import {DASHARRAY_LENGTH} from '../../constants';

import '../../actions';
import strings from '../../utils/strings';

class AudioPlayer extends React.Component {
	constructor() {
		super();

		this.uniqueId = `audio${getUniqueId()}`;

		this.state = {
			isPlaying: false,
			duration: 0, // ms
			currentTime: 0,
			isDone: false,
			isStarted: false,
		};
		this.tick = this.tick.bind(this);
		this.handleStartPlayer = this.handleStartPlayer.bind(this);
		this.handlePause = this.handlePause.bind(this);
		this.handlePlay = this.handlePlay.bind(this);
	}

	componentDidMount() {
		this.audioRef.addEventListener('durationchange', () => {
			const duration = this.audioRef.duration;

			if (duration) {
				this.setState({
					currentTime: duration,
					duration,
				});
			}

			if (this.props.autoplay) {
				this.handleStartPlayer();
			}
		});
	}

	componentWillReceiveProps(nextProps) {
		const {src} = this.props;
		if (src && src !== nextProps.src) {
			this.handlePause();
		}
	}

	componentDidUpdate(prevProps) {
		const {src} = this.props;
		if (prevProps.src && src !== prevProps.src) {
			this.audioRef.src = src;
		}
	}

	componentWillUnmount() {
		cancelAnimationFrame(this.animationFrame);
		this.setState({
			isDone: true,
		});
	}

	tick() {
		const audioRef = this.audioRef;
		const hasStoppedPlaying = audioRef.ended || audioRef.paused;
		if (hasStoppedPlaying) {
			this.setState({
				isDone: true,
			});
		} else {
			this.setState({
				currentTime: audioRef.currentTime,
			});
		}

		if (!this.state.isDone) {
			this.animationFrame = requestAnimationFrame(this.tick);
		}
	}

	completeCallback() {
		if (this.props.onComplete) {
			this.props.onComplete();
		}
	}

	handlePlay() {
		this.setState(
			{
				isDone: false,
				isPlaying: true,
			},
			() => {
				this.tick();
			}
		);

		if (typeof this.props.onStart === 'function') {
			this.props.onStart();
		}
	}

	handlePause() {
		this.setState({
			isPlaying: false,
		});

		const audioRef = this.audioRef;
		const isEnded = audioRef.ended;

		if (isEnded) {
			this.completeCallback();
		}

		cancelAnimationFrame(this.animationFrame);

		if (typeof this.props.onPause === 'function') {
			this.props.onPause();
		}
	}

	handleStartPlayer() {
		playPause(this.audioRef, this.props.refs);
	}

	render() {
		const {
			disabled,
			id = this.uniqueId,
			inline,
			refs,
			src,
			noBorder,
		} = this.props;

		const {currentTime, duration, isPlaying} = this.state;

		let progress = 0;
		if (isPlaying) {
			const percentageDone = (currentTime * 100) / duration;
			progress =
				DASHARRAY_LENGTH - (percentageDone * DASHARRAY_LENGTH) / 100;
		}

		return (
			<button
				className={classNames({
					'lit-circle': true,
					'lit-audio': true,
					'is-playing': isPlaying,
					inline: inline,
					noBorder: noBorder,
				})}
				disabled={disabled}
				onClick={() => {
					if (!this.audioRef) {
						return;
					}
					this.handleStartPlayer();
				}}
				title={disabled ? strings.audioMax : null}
			>
				<svg
					className="lit-circle__interactive"
					height="90"
					style={{enableBackground: 'new 0 0 90 90'}}
					version="1.1"
					viewBox="0 0 90 90"
					width="90"
					x="0px"
					xmlSpace="preserve"
					xmlns="http://www.w3.org/2000/svg"
					xmlnsXlink="http://www.w3.org/1999/xlink"
					y="0px"
				>
					<g transform="rotate(-84 45 45)">
						<circle
							className="lit-audio__outline"
							cx="45"
							cy="45"
							fill="none"
							r="37"
							stroke="rgba(0,0,0,0.1)"
							strokeLinecap="round"
							strokeWidth="5"
						/>
						<circle
							className="lit-audio__progress"
							cx="45"
							cy="45"
							fill="none"
							r="37"
							stroke="#fff"
							strokeLinecap="round"
							strokeWidth="6"
							style={{
								strokeDashoffset: `${progress}`,
							}}
						/>
					</g>
				</svg>
				<div className="lit-audio__icon" />
				<audio
					onPause={this.handlePause}
					onPlay={this.handlePlay}
					ref={(ref) => {
						this.audioRef = ref;
						if (refs && id) {
							refs[id] = ref;
						}
					}}
				>
					<source src={src} />
				</audio>
			</button>
		);
	}
}

export default AudioPlayer;
