//TODO-SIMEN REPLACE ALL THESE WITH LOCALIZED SOUNDS

import beardWrong from '../../../assets/sounds/child/se/SWSCRC1-15.mp3';
import matWrong from '../../../assets/sounds/child/se/SWSCRC1-14.mp3';
import tutorial6Sound from '../../../assets/sounds/child/se/SWSCRC1-12.mp3';
import munCorrect from '../../../assets/sounds/child/se/SWSCRC1-13.mp3';
import womenWrong from '../../../assets/sounds/child/se/SWSCRC1-16.mp3';

import sawCorrect from '../../../assets/sounds/child/se/SWSCRC1-08.mp3';
import trainWrong from '../../../assets/sounds/child/se/SWSCRC1-11.mp3';
import plasterWrong from '../../../assets/sounds/child/se/SWSCRC1-10.mp3';
import carWrong from '../../../assets/sounds/child/se/car-is-wrong.mp3';
import getWrong from '../../../assets/sounds/child/se/SWSCRC1-09.mp3';

import tutorial3Sound from '../../../assets/sounds/child/se/SWSCRC1-07.mp3';
import calfWrong from '../../../assets/sounds/child/se/SWSCRC1-05.mp3';
import kombCorrect from '../../../assets/sounds/child/se/SWSCRC1-03.mp3';
import rabbitWrong from '../../../assets/sounds/child/se/SWSCRC1-06.mp3';
import lampWrong from '../../../assets/sounds/child/se/SWSCRC1-04.mp3';
import tutorial1Sound from '../../../assets/sounds/child/se/SWSCRC1-02.mp3';

import blueImg from '../assets/blue-min.png';
import skyImg from '../assets/Sky-min.PNG';
import greenImg from '../assets/green-min.png';
import yellowImg from '../assets/yellow-min.png';
import redImg from '../assets/red-min.png';
import purpleImg from '../assets/purple-min.png';
import cowImg from '../assets/Ku-min.PNG';
import bulbImg from '../assets/Lys-min.PNG';
import mittenImg from '../assets/mittens-min.png';
import pencilImg from '../assets/pencil-min.png';
import puppyImg from '../assets/Hund-min.PNG';
import lamb from '../assets/Lam-min.PNG';
import komb from '../assets/Kam-min.PNG';
import lamp from '../assets/lampa-min.png';
import man from '../assets/man-min.png';

import rabbit from '../assets/kanin-min.png';
import calf from '../assets/kalv-min.png';
import blood from '../assets/blood-min.png';
import get from '../assets/får-min.png';
import plaster from '../assets/plåster-min.png';
import train from '../assets/tåg-min.png';
import saw from '../assets/Sage-min.PNG';
import women from '../assets/kvinna-min.png';
import munIMG from '../assets/mun-min.png';
import mat from '../assets/mat-min.png';
import beard from '../assets/skägg-min.png';

import starterTesten from '../../../assets/sounds/child/se/SWSCRC1-17.mp3';

export const starterTestenSound = starterTesten;

export const bytteLydenExample = {};

export const tutorialAssignment = {
	id: 1,
	choices: [
		{
			word: 'Lys',
		},
		{
			word: 'Lese',
		},
		{
			word: 'Katt',
		},
		{
			isCorrect: true,
			word: 'Kam',
		},
	],
};
export const assignment1 = [
	{
		id: 1,
		mainImg: lamb,
		mainSound: tutorial1Sound,
		text: 'Vilket ord får du om du byter ut det första ljudet /l/ i lamm mot ljudet /k/?',
		choices: [
			{
				remark: true,
				word: 'Kam',
				comment: `Kam är helt rätt. När du byter ut /l/ i lamm mot /k/ blir det nya ordet kam.`,
				sound: kombCorrect,
				img: komb,
			},
			{
				remark: false,
				word: 'Lampa',
				comment: 'Tyvärr är lampa fel',
				sound: lampWrong,
				img: lamp,
			},
			{
				remark: false,
				word: 'Kalv',
				comment: 'Tyvärr är kalv fel',
				sound: calfWrong,
				img: calf,
			},
			{
				remark: false,
				word: 'Kanin',
				comment: 'Tyvärr är kanin fel',
				sound: rabbitWrong,
				img: rabbit,
			},
		],
	},
];

export const assignment2 = [
	{
		id: 2,
		text: 'Vilket ord får du om du byter ut det sista ljudet /r/ i sår mot ljudet /g/?',
		mainImg: blood,
		mainSound: tutorial3Sound,
		choices: [
			{
				remark: false,
				word: 'Får',
				comment: 'Tyvärr är får fel',
				sound: getWrong,
				img: get,
			},
			{
				remark: false,
				word: 'Plåster',
				comment: 'Tyvärr är plåster fel',
				sound: plasterWrong,
				img: plaster,
			},
			{
				remark: false,
				word: 'Tåg',
				comment: 'Tyvärr är tåg fel',
				sound: trainWrong,
				img: train,
			},
			{
				remark: true,
				word: 'Såg',
				comment:
					'Såg är helt rätt. När du byter ut /r/ i sår mot /g/ blir det nya ordet såg.',
				sound: sawCorrect,
				img: saw,
			},
		],
	},
];

export const assignment3 = [
	{
		id: 3,
		mainImg: man,
		mainSound: tutorial6Sound,
		text: 'Vilket ord får du om du byter ut det mellersta ljudet /a/ i man mot ljudet /u/?',
		choices: [
			{
				remark: false,
				word: 'Mat',
				comment: 'Tyvärr är mat fel',
				sound: matWrong,
				img: mat,
			},
			{
				remark: true,
				word: 'Mun',
				comment:
					'Mun är helt rätt. När du byter ut /a/ i man mot /u/ blir det nya ordet mun.',
				sound: munCorrect,
				img: munIMG,
			},
			{
				remark: false,
				word: 'Skägg',
				comment: 'Tyvärr är skägg fel',
				sound: beardWrong,
				img: beard,
			},
			{
				remark: false,
				word: 'Kvinna',
				comment: 'Tyvärr är kvinna fel',
				sound: womenWrong,
				img: women,
			},
		],
	},
];
export const assignment4 = [
	{
		id: 4,
		mainImg: puppyImg,
		choices: [
			{
				remark: true,
				word: 'modil',
				comment: '',
				sound: carWrong,
				img: redImg,
			},
			{
				remark: true,
				word: 'vuks',
				comment: '',
				sound: carWrong,
				img: greenImg,
			},
			{
				remark: true,
				word: 'fålk',
				comment: '',
				sound: carWrong,
				img: blueImg,
			},
			{
				remark: true,
				word: 'flynt',
				comment: '',
				sound: carWrong,
				img: yellowImg,
			},
		],
	},
];
export const assignment5 = [
	{
		id: 5,
		mainImg: puppyImg,
		choices: [
			{
				word: 'modil',
				comment: '',
				sound: carWrong,
				img: redImg,
			},
			{
				word: 'vuks',
				comment: '',
				sound: carWrong,
				img: greenImg,
			},
			{
				word: 'fålk',
				comment: '',
				sound: carWrong,
				img: blueImg,
			},
			{
				word: 'flynt',
				comment: '',
				sound: carWrong,
				img: yellowImg,
			},
		],
	},
];

export const assignments = [
	{
		id: 2,
		mainImg: puppyImg,
		choices: [
			{
				word: 'modil',
				comment: '',
				sound: carWrong,
				img: redImg,
			},
			{
				word: 'vuks',
				comment: '',
				sound: carWrong,
				img: greenImg,
			},
			{
				word: 'fålk',
				comment: '',
				sound: carWrong,
				img: blueImg,
			},
			{
				word: 'flynt',
				comment: '',
				sound: carWrong,
				img: yellowImg,
			},
		],
	},
	{
		id: 3,
		mainImg: cowImg,
		choices: [
			{
				word: 'vorr',
				comment:
					'Vorr er riktig, fordi det er et spørreord som vi bruker når vi for eksempel lurer på hvor noen befinner seg. Vi skriver ikke ordet slik, men uttaler det slik.',
				sound: carWrong,
				img: greenImg,
			},
			{
				word: 'blastell',
				comment:
					'Blastell er feil. Dette ordet finnes ikke på norsk, og det betyr ingen ting.',
				sound: carWrong,
				img: yellowImg,
			},
			{
				word: 'peano',
				comment:
					'Peano er feil, fordi vi sier ikke peano – men piano. Ordet peano finnes ikke på norsk, og det betyr ingen ting.',
				sound: carWrong,
				img: skyImg,
			},
			{
				word: 'knain',
				comment:
					'Knain er feil. Dette ordet finnes ikke på norsk, og det betyr ingen ting.',
				sound: carWrong,
				img: blueImg,
			},
		],
	},
	{
		id: 4,
		mainImg: pencilImg,
		choices: [
			{
				word: 'vorr',
				comment:
					'Vorr er riktig, fordi det er et spørreord som vi bruker når vi for eksempel lurer på hvor noen befinner seg. Vi skriver ikke ordet slik, men uttaler det slik.',
				sound: carWrong,
				img: purpleImg,
			},
			{
				word: 'blastell',
				comment:
					'Blastell er feil. Dette ordet finnes ikke på norsk, og det betyr ingen ting.',
				sound: carWrong,
				img: yellowImg,
			},
			{
				word: 'peano',
				comment:
					'Peano er feil, fordi vi sier ikke peano – men piano. Ordet peano finnes ikke på norsk, og det betyr ingen ting.',
				sound: carWrong,
				img: skyImg,
			},

			{
				word: 'brillter',
				comment:
					'Brillter er feil, fordi vi sier ikke brillter – men briller. Ordet brillter finnes ikke på norsk, og det betyr ingen ting.',
				sound: carWrong,
				img: redImg,
			},
		],
	},
	{
		id: 5,
		mainImg: bulbImg,
		choices: [
			{
				word: 'vorr',
				comment:
					'Vorr er riktig, fordi det er et spørreord som vi bruker når vi for eksempel lurer på hvor noen befinner seg. Vi skriver ikke ordet slik, men uttaler det slik.',
				sound: carWrong,
				img: greenImg,
			},
			{
				word: 'blastell',
				comment:
					'Blastell er feil. Dette ordet finnes ikke på norsk, og det betyr ingen ting.',
				sound: carWrong,
				img: purpleImg,
			},

			{
				word: 'knain',
				comment:
					'Knain er feil. Dette ordet finnes ikke på norsk, og det betyr ingen ting.',
				sound: carWrong,
				img: blueImg,
			},
			{
				word: 'brillter',
				comment:
					'Brillter er feil, fordi vi sier ikke brillter – men briller. Ordet brillter finnes ikke på norsk, og det betyr ingen ting.',
				sound: carWrong,
				img: yellowImg,
			},
		],
	},
	{
		id: 6,
		mainImg: mittenImg,
		choices: [
			{
				word: 'vorr',
				comment:
					'Vorr er riktig, fordi det er et spørreord som vi bruker når vi for eksempel lurer på hvor noen befinner seg. Vi skriver ikke ordet slik, men uttaler det slik.',
				sound: carWrong,
				img: greenImg,
			},
			{
				word: 'blastell',
				comment:
					'Blastell er feil. Dette ordet finnes ikke på norsk, og det betyr ingen ting.',
				sound: carWrong,
				img: yellowImg,
			},

			{
				word: 'knain',
				comment:
					'Knain er feil. Dette ordet finnes ikke på norsk, og det betyr ingen ting.',
				sound: carWrong,
				img: blueImg,
			},
			{
				word: 'brillter',
				comment:
					'Brillter er feil, fordi vi sier ikke brillter – men briller. Ordet brillter finnes ikke på norsk, og det betyr ingen ting.',
				sound: carWrong,
				img: redImg,
			},
		],
	},
];
