import React from 'react';
import {connect} from 'react-redux';
import strings from 'utils/strings';

import AudioPlayer from 'components/AudioPlayer';
import audioNo from '../../assets/sounds/child/se/SWSCRC1-01.mp3';
import audioNn from '../../assets/sounds/child/se/SWSCRC1-01.mp3';
import videoBiltoPil from './assets/video_byttelyd.mp4';
const languageObj = {
	no: audioNo,
	nn: audioNn,
	se: audioNo
};

class Intro extends React.Component {
	constructor(props) {
		super(props);
		this.audio = languageObj[strings.getLanguage()];
		this.video = videoBiltoPil;
	}

	render() {
		const {onNextStep} = this.props;
		let videoRef;
		return (
			<div>
				<p>
					<AudioPlayer
						onStart={() => {
							videoRef.play();
						}}
						autoplay
						inline
						src={this.audio}
					/>
					{strings.formatString(
						strings.tulleordtesteneksempel1,
						<strong>b</strong>,
						<strong>bil</strong>,
						<strong>p</strong>,
						<strong>pil</strong>
					)}
				</p>
				<div className="lit-preview">
					<p className="lit-preview__label">{'Eksempel'}</p>
					<video
						className="lit-preview__video"
						loop
						ref={(ref) => {
							videoRef = ref;
						}}
						src={this.video}
					/>
				</div>
				<p>{strings.tulleordtestenPractice}</p>
				<button
					className="lit-btn bg-primary"
					onClick={() => {
						this.props.onShowAvatar(false);
						onNextStep();
					}}
				>
					{strings.toPractice}
				</button>
			</div>
		);
	}
}

const mapStateToProps = () => {
	return {};
};

export default connect(mapStateToProps)(Intro);
