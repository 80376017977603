import audio1 from '../../../../assets/sounds/child/se/SWSCRC2-05.mp3';
import audio2 from '../../../../assets/sounds/child/se/SWSCRC2-06.mp3';

const assignments = [
	{
		id: 1,
		text: 'kattsolpånoll',
		answer: 'katt sol på noll',
		audio: audio1,
		count: 'andre',
		hasPlayed: false,
	},
	{
		id: 2,
		text: 'pennadrickatiout',
		answer: 'penna dricka tio ut',
		audio: audio2,
		count: 'andre',
		hasPlayed: false,
	},
];
export default assignments;
