import React from 'react';

export const TextGrade1 = [
	{
		para: (
			<span>
				<h2 className="text-center">{`Lillen`}</h2>
				<p>
					{`Jag heter Sigge och nu ska jag berätta om något som hände mig 
                    förra veckan. Jag var på väg till skolan. Vid tunneln som går under 
                    motorvägen stod några stora barn från mellanstadiet. De var läskiga 
                    så jag gick så fort jag kunde förbi dem.`}
				</p>
			</span>
		),
		questions: [
			{
				id: 1,
				text: 'Varför gick Sigge fort?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'För att komma i tid till skolan.',
					},
					{
						letter: 'b',
						identity: 'b',
						choice: 'För barnen från mellanstadiet jagade Sigge.',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'För att de stora barnen var läskiga.',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'För att det var kul.',
					},
				],
			},
		],
	},
	{
		para: (
			<span>
				{<h2 className="text-center">{`2 av 8`}</h2>}
				<p>{`- Hallå!, ropade en av dem.`}</p>
				<p>
					{`Han hade långt hår och en svart jacka med nitar på. Hjälp, tänkte 
                    jag, han vill säkert bråka med mig. Men det var inte långt kvar till 
                    kolan. Jag började springa. Som tur var följde de stora inte efter 
                    mig.`}
				</p>
			</span>
		),
		questions: [
			{
				id: 2,
				text: 'Vem ropade hallå?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'Ett stort barn från mellanstadiet.',
					},
					{
						letter: 'b',
						identity: 'b',
						choice: 'En vuxen med svart jacka med nitar på.',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'En kompis.',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'En osynlig person.',
					},
				],
			},
		],
	},

	{
		para: (
			<span>
				{<h2 className="text-center">{`3 av 8`}</h2>}
				<p>
					{`Efter skolan sa mamma åt mig att gå och handla mjölk. Jag tog på 
                    mig jackan och gick mot affären.`}
				</p>
			</span>
		),
		questions: [
			{
				id: 3,
				text: 'Vad sa mamma?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'Ta på dig en jacka.',
					},
					{
						letter: 'b',
						identity: 'b',
						choice: 'Handla bröd.',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'Affären är stängd.',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'Gå och köp mjölk.',
					},
				],
			},
		],
	},
	{
		para: (
			<span>
				{<h2 className="text-center">{`4 av 8`}</h2>}
				<p>
					{`Jag tog gångvägen genom parken. Då såg jag den långhåriga killen 
                    igen. Han stod en bit bort och tittade rakt på mig. Jag stannade.`}
				</p>
			</span>
		),
		questions: [
			{
				id: 4,
				text: 'Var går Sigge?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'På trottoaren.',
					},
					{
						letter: 'b',
						identity: 'b',
						choice: 'I en tunnel.',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'På en bilväg genom en park.',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'På en gångväg genom en park.',
					},
				],
			},
		],
	},
	{
		para: (
			<span>
				<h2 className="text-center">{`5 av 8`}</h2>
				<p>{`- Hit!, skrek killen.`}</p>
				<p>{`Hela min kropp frös till is. Han hade sett mig!`}</p>
			</span>
		),
		questions: [
			{
				id: 5,
				text: 'Vad kände Sigge när killen skrek?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'Rädsla.',
					},
					{
						letter: 'b',
						identity: 'b',
						choice: 'Glädje.',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'Nyfikenhet.',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'Ilska.',
					},
				],
			},
		],
	},
	{
		para: (
			<span>
				<h2 className="text-center">{`6 av 8`}</h2>
				<p>{`- Kom hit, Lillen!, skrek killen igen.`}</p>
				<p>{`Då skuttade en liten hund fram till mig. Jag älskar hundar och kunde inte låta bli att klappa den. Den buffade med nosen mot mig för att 
                    jag skulle klappa mer.`}</p>
			</span>
		),
		questions: [
			{
				id: 6,
				text: 'Vad vill hunden?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'Skälla.',
					},
					{
						letter: 'b',
						identity: 'b',
						choice: 'Få mer klappar.',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'Skrämmas.',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'Få mat.',
					},
				],
			},
		],
	},
	{
		para: (
			<span>
				<h2 className="text-center">{`7 av 8`}</h2>
				<p>{`- Lillen fy, du ska komma till mig när jag ropar.`}</p>
				<p>{`Jag lyfte blicken från hunden. Den långhåriga killen hade kommit fram till oss. Hunden viftade på svansen och killen satte på den ett 
                    koppel.`}</p>
			</span>
		),
		questions: [
			{
				id: 7,
				text: 'Vem säger den långhåriga killen fy till?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'Sigge.',
					},
					{
						letter: 'b',
						identity: 'b',
						choice: 'Sina kompisar.',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'Sin mamma.',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'Sin hund.',
					},
				],
			},
		],
	},
	{
		para: (
			<span>
				<h2 className="text-center">{`8 av 8`}</h2>
				<p>{`Sen tog han upp nåt ur sin jackficka. Det var min mössa.`}</p>
				<p>{`- Du tappade den i morse, sa han och log snällt mot mig.`}</p>
				<p>{`- Jag ropade men du hörde inte.`}</p>
				<p>{`- Tack, mumlade jag och blev röd i ansiktet. Jag hade tänkt helt fel. Så knäppt.`}</p>
			</span>
		),
		questions: [
			{
				id: 8,
				text: 'Vad hade den långhåriga killen hittat?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'Sigges hund.',
					},
					{
						letter: 'b',
						identity: 'b',
						choice: 'Sigges vante.',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'Sigges jacka.',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'Sigges mössa.',
					},
				],
			},
		],
	},
];

export const TextGrade2 = [
	{
		para: (
			<span>
				<h2 className="text-center">{`Ingen fegis`}</h2>
				<p>{`Astrid hade klättrat upp på mormors garagetak.`}</p>
				<p>{`- Kom upp, Myran!, tjatade hon.`}</p>
				<p>{`- Man ser hela trädgården och halva världen härifrån!`}</p>
				<p>{`Men jag stod kvar på marken vid stegen. Det snurrade i huvudet vid 
                    blotta tanken att klättra upp.`}</p>
			</span>
		),
		questions: [
			{
				id: 1,
				text: 'När snurrar det i Myrans huvud?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'När Myran ser Astrid.',
					},
					{
						letter: 'b',
						identity: 'b',
						choice: 'När Myran tittar på stegen.',
					},
					{
						letter: 'c',
						identity: 'd',
						choice: 'När Myran klättrat upp till Astrid.',
					},
					{
						letter: 'd',
						identity: 'c',
						choice: 'När Myran tänker på att klättra upp till Astrid.',
					},
				],
			},
		],
	},
	{
		para: (
			<span>
				<h2 className="text-center">{`2 av 8`}</h2>
				<p>{`Efter en stund klättrade Astrid ner till mig igen.`}</p>
				<p>{`- Nu bär vi byrån till kojan!, sa hon.`}</p>
				<p>{`- Ja, kul, sa jag.`}</p>
				<p>{`Vi hade byggt en koja i skogen och i ladan fanns en gammal byrå 
                    som mormor sagt att vi fick ta dit. Den hade blankt brunt trä och 
                    många lådor. Däri skulle vi kunna ha vårt porslin och andra 
                    nödvändiga saker som vi burit till kojan.`}</p>
			</span>
		),
		questions: [
			{
				id: 2,
				text: 'Var står byrån?',
				choices: [
					{
						letter: 'a',
						identity: 'b',
						choice: 'I kojan de byggt.',
					},
					{
						letter: 'b',
						identity: 'c',
						choice: 'På hustaket.',
					},
					{
						letter: 'c',
						identity: 'd',
						choice: 'Vid ett brunt träd.',
					},
					{
						letter: 'd',
						identity: 'a',
						choice: 'I ladan.',
					},
				],
			},
		],
	},

	{
		para: (
			<span>
				<h2 className="text-center">{`3 av 8`}</h2>
				<p>{`På väg till ladan passerade vi ridbanan där mormor travade runt på Hjärtat. Astrid stannade till vid staketet och frågade mormor om vi fick rida.`}</p>
				<p>{`- Visst, sa mormor.`}</p>
				<p>{`Astrid satt upp och snart galopperade hon på Hjärtat.`}</p>
			</span>
		),
		questions: [
			{
				id: 3,
				text: 'Vem är Hjärtat?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'En hund.',
					},
					{
						letter: 'b',
						identity: 'b',
						choice: 'Ett smeknamn för Astrid.',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'Mormors namn.',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'Mormors häst.',
					},
				],
			},
		],
	},
	{
		para: (
			<span>
				<h2 className="text-center">{`4 av 8`}</h2>
				<p>{`Mormor frågade om jag också ville sitta upp men jag skakade på huvudet.`}</p>
				<p>{`- Myran är för feg, sa Astrid.`}</p>
				<p>{`- Du gillar väl inte att rida?, sa mormor.`}</p>
				<p>{`Jag svarade inte.`}</p>
			</span>
		),
		questions: [
			{
				id: 4,
				text: 'Vad menar mormor med det hon säger?',
				choices: [
					{
						letter: 'a',
						identity: 'd',
						choice: 'Att Myran kanske inte vill rida.',
					},
					{
						letter: 'b',
						identity: 'b',
						choice: 'Att Astrid inte gillar att rida.',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'Att Myran ska skämmas och känna sig feg.',
					},
					{
						letter: 'd',
						identity: 'a',
						choice: 'Att Myran gillar att rida.',
					},
				],
			},
		],
	},

	{
		para: (
			<span>
				<h2 className="text-center">{`5 av 8`}</h2>
				<p>{`Astrid hoppade av Hjärtat och vi gick till ladan för att hämta byrån.`}</p>
				<p>{`När vi lyfte den prasslade det till i en av de halvöppna lådorna. En söt liten mus stack upp huvudet. Astrid tjöt till och sen sa hon att 
                    byrån var ful som stryk. Jag såg hennes bleka kinder och förstod att 
                    hon inte menade det.`}</p>
			</span>
		),
		questions: [
			{
				id: 5,
				text: 'Varför sa Astrid att byrån var ful som stryk?',
				choices: [
					{
						letter: 'a',
						identity: 'b',
						choice: 'För att hon hade hittat en finare byrå.',
					},
					{
						letter: 'b',
						identity: 'd',
						choice: 'För att hon kallade Myran feg.',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'För att hon hellre ville rida.',
					},
					{
						letter: 'd',
						identity: 'a',
						choice: 'För att hon blev rädd för musen.',
					},
				],
			},
		],
	},

	{
		para: (
			<span>
				<h2 className="text-center">{`6 av 8`}</h2>
				<p>
					{`Med en plastbunke lyckades jag fånga musen och bära ut den.`}
				</p>
			</span>
		),
		questions: [
			{
				id: 6,
				text: 'Vad bär Myran ut?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'En tom plastbunke.',
					},
					{
						letter: 'b',
						identity: 'c',
						choice: 'Det står inte i texten.',
					},
					{
						letter: 'c',
						identity: 'b',
						choice: 'Musen.',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'Byrån.',
					},
				],
			},
		],
	},

	{
		para: (
			<span>
				<h2 className="text-center">{`7 av 8`}</h2>
				<p>
					{`När jag kom in igen tittade Astrid på mig med en helt ny blick.`}
				</p>
				<p>{`- Jag ska aldrig kalla dig feg mer, sa hon.`}</p>
				<p>{`- Vi är rädda för olika saker, sa jag.`}</p>
			</span>
		),
		questions: [
			{
				id: 7,
				text: 'Vad är Myran och Astrid rädda för?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'Myran är rädd för hästar. Astrid är rädd för höjder.',
					},
					{
						letter: 'b',
						identity: 'd',
						choice: 'Myran är rädd för höga höjder. Astrid är rädd för möss.',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'Både Myran och Astrid är rädda för att rida.',
					},
					{
						letter: 'd',
						identity: 'b',
						choice: 'Myran är rädd för möss. Astrid är rädd för hästar.',
					},
				],
			},
		],
	},

	{
		para: (
			<span>
				<h2 className="text-center">{`8 av 8`}</h2>
				<p>
					{`Sen hjälptes vi åt att bära byrån till vår koja och den passade precis så bra som vi hade tänkt.`}
				</p>
			</span>
		),
		questions: [
			{
				id: 8,
				text: 'Vilka bär byrån?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'Astrid och mormor.',
					},
					{
						letter: 'b',
						identity: 'b',
						choice: 'Myran och mormor.',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'Myran bär den själv.',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'Astrid och Myran.',
					},
				],
			},
		],
	},
];
export const TextGrade3 = [
	{
		para: (
			<span>
				<h2 className="text-center">{`Stoppa mobbingen!`}</h2>
				<p>
					{`Vår lärare sa att vi skulle göra affischer med budskap. Liam och 
                     Munir sa att de ville jobba ihop och göra en mot äcklig skolmat. 
                     Donja, Emma och Nibla sa att de skulle göra en mot miljöförstöring.`}
				</p>
			</span>
		),
		questions: [
			{
				id: 1,
				text: 'Vad för budskap vill Liam och Munir ha på sin affisch?',
				choices: [
					{
						letter: 'a',
						identity: 'c',
						choice: 'Sluta gör äcklig skolmat.',
					},
					{
						letter: 'b',
						identity: 'a',
						choice: 'Skolmaten är god.',
					},
					{
						letter: 'c',
						identity: 'b',
						choice: 'Sluta servera skolmat.',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'Stoppa miljöförstöringen.',
					},
				],
			},
		],
	},

	{
		para: (
			<span>
				<h2 className="text-center">{`2 av 8`}</h2>
				<p>{`- Vi kan jobba ihop, Kakan, sa Tindra till mig. Vi gör en affisch mot mobbing.`}</p>
				<p>{`- Visst, sa jag.`}</p>
				<p>{`Framför oss satt Kevin. Han hade som vanligt ingen att jobba med. 
                    Han hade ingen kompis i vår klass och ibland var de andra taskiga 
                    mot honom. En gång hade Liam knyckt hans glasögon och tappat 
                    dem i stengolvet så att de gick sönder.`}</p>
			</span>
		),

		questions: [
			{
				id: 2,
				text: 'Varför har Kevin ingen att jobba med?',
				choices: [
					{
						letter: 'a',
						identity: 'b',
						choice: 'För att Liam har knyckt hans glasögon.',
					},
					{
						letter: 'b',
						identity: 'a',
						choice: 'För att han är utanför i klassen.',
					},
					{
						letter: 'c',
						identity: 'd',
						choice: 'För att hans glasögon har gått sönder.',
					},
					{
						letter: 'd',
						identity: 'c',
						choice: 'För att han sitter på raden framför.',
					},
				],
			},
		],
	},

	{
		para: (
			<span>
				<h2 className="text-center">{`3 av 8`}</h2>
				<p>{`Jag lutade mig fram mot Kevin.`}</p>
				<p>{`- Vill du vara med oss?`}</p>
				<p>{`Tindras ansikte drogs ihop till en sur citron. Men Kevin strålade upp 
                    som en sol.`}</p>
			</span>
		),

		questions: [
			{
				id: 3,
				text: 'Vad uttrycker Tindra?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'Att Kevin strålar som en sol.',
					},
					{
						letter: 'b',
						identity: 'b',
						choice: 'Att det ska bli roligt att jobba med Kevin.',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: ' Att hon vill äta en citron.',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'Att hon blir sur för att Kakan frågar om Kevin vill jobba med dem.',
					},
				],
			},
		],
	},

	{
		para: (
			<span>
				<h2 className="text-center">{`4 av 8`}</h2>
				<p>{`- Gärna, sa Kevin.`}</p>
				<p>
					{`Jag förklarade för honom vilket budskap vi hade tänkt att ha på vår 
                    affisch.`}
				</p>
			</span>
		),

		questions: [
			{
				id: 4,
				text: 'Vad förklarar Kakan?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'Att de ska göra affischer.',
					},
					{
						letter: 'b',
						identity: 'b',
						choice: 'Att han ska jobba med dem.',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'Att de inte ska göra någon affisch.',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'Att de ska skriva ett budskap mot mobbing.',
					},
				],
			},
		],
	},

	{
		para: (
			<span>
				<h2 className="text-center">{`5 av 8`}</h2>
				<p>{`- Vi skriver ”Mobbing är bajs”, föreslog han.`}</p>
				<p>{`- Ja, det blir bra, sa jag.`}</p>
				<p>{`- Det blir det väl inte, sa Tindra. Det låter äckligt. ”Stoppa mobbing!” 
                    skriver vi.`}</p>
			</span>
		),

		questions: [
			{
				id: 5,
				text: 'Vad tycker Tindra låter äckligt?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'Att skriva bajs.',
					},
					{
						letter: 'b',
						identity: 'b',
						choice: 'Att mobbas.',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'Att bajsa.',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'Att göra en affisch mot mobbing.',
					},
				],
			},
		],
	},

	{
		para: (
			<span>
				<h2 className="text-center">{`6 av 8`}</h2>
				<p>{`- Funkar väl lika bra, sa Kevin.`}</p>
				<p>{`Han tog en röd penna och började skriva på pappret som vår lärare 
                    gett oss för uppgiften.`}</p>
				<p>{`- Nej, sluta, sa Tindra.`}</p>
				<p>{`- Vadå?, sa Kevin förvånat.`}</p>
				<p>{`- Vi ska faktiskt bestämma vilken färg vi ska skriva med tillsammans innan du börjar.`}</p>
				<p>{`Kevin kröp ihop i bänken och såg ut som en ledsen hund.`}</p>
				<p>{`- Förlåt. Vad ska vi ha för färg tycker ni?`}</p>
				<p>{`- Lila!, sa Tindra.`}</p>
			</span>
		),

		questions: [
			{
				id: 6,
				text: 'Hur reagerar Kevin på det Tindra säger?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'Han blir arg och säger emot henne.',
					},
					{
						letter: 'b',
						identity: 'b',
						choice: 'Han skäms och frågar vilken penna de ska skriva med.',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'Han blir glad för att lila är en finare färg.',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'Han frågar om de ska använda en lila penna.',
					},
				],
			},
		],
	},

	{
		para: (
			<span>
				<h2 className="text-center">{`7 av 8`}</h2>
				<p>{`Kevin tog en lila penna och började skriva med stora bokstäver. ST 
                    hann han skriva innan Tindra ryckte pennan ur hans hand.`}</p>
			</span>
		),

		questions: [
			{
				id: 7,
				text: 'Vad håller Kevin på med när Tindra tar hans penna?',
				choices: [
					{
						letter: 'a',
						identity: 'd',
						choice: 'Skriver de första bokstäverna på deras affisch.',
					},
					{
						letter: 'b',
						identity: 'c',
						choice: 'Skriver med en röd penna.',
					},
					{
						letter: 'c',
						identity: 'b',
						choice: 'Skriver andra bokstäver än de Tindra sagt.',
					},
					{
						letter: 'd',
						identity: 'a',
						choice: 'Skriver med för små bokstäver.',
					},
				],
			},
		],
	},

	{
		para: (
			<span>
				<h2 className="text-center">{`8 av 8`}</h2>
				<p>{`- Du skriver faktiskt fult, säger Tindra. Det är bättre jag skriver.`}</p>
				<p>{`Ilskan kröp uppför min ryggrad och spreds ut i hela mig som ett 
                    nyårsfyrverkeri. Nu fick det vara nog.`}</p>
				<p>{`- Kevin skriver inte alls fult, sa jag.`}</p>
				<p>{`Sen hämtade jag ett nytt papper. Med den röda pennan skrev jag 
                med stora bokstäver: ”Stoppa mobbingen!”`}</p>
				<p>{`- Du borde läsa den här affischen, Tindra, sa jag.`}</p>
				<p>{`Tindra såg ut som ett levande, mycket oskyldigt, frågetecken.`}</p>
				<p>{`- Vad menar du?, fnös hon.`}</p>
				<p>{`Hon lät arg men Kevin tittade på mig och log. Han förstod i alla fall 
                    vad jag menade.`}</p>
			</span>
		),

		questions: [
			{
				id: 8,
				text: 'Varför säger Kakan att Tindra ska läsa på affischen?',
				choices: [
					{
						letter: 'a',
						identity: 'c',
						choice: 'För att Tindra inte kan stava.',
					},
					{
						letter: 'b',
						identity: 'd',
						choice: 'För att Tindra mobbas.',
					},
					{
						letter: 'c',
						identity: 'b',
						choice: 'För att affischen blev fin.',
					},
					{
						letter: 'd',
						identity: 'a',
						choice: 'För att Kevin har skrivit affischen.',
					},
				],
			},
		],
	},
];

export const TextGrade4 = [
	{
		para: (
			<span>
				<h2 className="text-center">{`Ny i klassen`}</h2>
				<p>
					{`Mamma hade fått jobb i annan stad och då har barn tydligen inget 
                    att säga till om utan de måste flytta och lämna alla kompisar och 
                    byta till en skola där de inte känner nån.`}
				</p>
				<p>
					{`Idag var min första dag. Min mage kändes som den ätit en rutten 
                    hamburgare men jag förstod att det bara var nerver som kröp runt.`}
				</p>
				<p>
					{`- Lycka till Loppan, sa mamma när hon släppte av mig vid skolan.`}
				</p>
			</span>
		),
		questions: [
			{
				id: 1,
				text: 'Varför ska Loppan börja i en ny skola?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'För att det är kul att byta skola.',
					},
					{
						letter: 'b',
						identity: 'c',
						choice: 'För att familjen måste flytta.',
					},
					{
						letter: 'c',
						identity: 'b',
						choice: 'För att barn kan bestämma sånt.',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'För att Loppan blivit ovän med sina kompisar på förra skolan.',
					},
				],
			},
		],
	},
	{
		para: (
			<span>
				<h2 className="text-center">{`2 av 8`}</h2>
				<p>
					{`På skolgården var det fullt av barn, men ingen jag kände, ingen jag 
                    kunde gå fram till och börja prata med. Vad skulle jag göra? Jag gick 
                    några trevande steg in på skolgården.`}
				</p>
			</span>
		),
		questions: [
			{
				id: 2,
				text: 'Vilka var på skolgården?',
				choices: [
					{
						letter: 'a',
						identity: 'b',
						choice: 'Dumma personer som ropar elaka saker.',
					},
					{
						letter: 'b',
						identity: 'a',
						choice: 'Nya okända skolkamrater.',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'Det var inga barn på skolgården.',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'Bara yngre småbarn.',
					},
				],
			},
		],
	},
	{
		para: (
			<span>
				<h2 className="text-center">{`3 av 8`}</h2>
				<p>{`Då kom en tjej med långt lockigt hår fram till mig.`}</p>
				<p>{`- Hej, är du den nya?, sa hon.`}</p>
				<p>
					{`Jag försökte forma om ansiktsmusklerna till ett självsäkert leende 
                    men min pipiga röst avslöjade mig när jag svarade:`}
				</p>
				<p>{`- Ja, jag är ny.`}</p>
				<p>
					{`- Du ska gå i min klass. Johanna, vår lärare, sa att du skulle börja 
                    idag. Alva heter jag.`}
				</p>
			</span>
		),
		questions: [
			{
				id: 3,
				text: 'Vem är Alva?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'Loppans lärare.',
					},
					{
						letter: 'b',
						identity: 'b',
						choice: 'Det står inte i texten.',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'Loppans mamma.',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'Loppans nya klasskamrat.',
					},
				],
			},
		],
	},
	{
		para: (
			<span>
				<h2 className="text-center">{`4 av 8`}</h2>
				<p>
					{`Vi pratade en lång stund och jag började andas normalt och känna 
                    igen min röst igen. Hurra, tänkte jag. Jag har hittat en kompis som 
                    kommer hjälpa mig att överleva första skoldagen.`}
				</p>
				<p>{`En lärare kom ut och ringde i en bjällra.`}</p>
				<p>{`- Nu börjar vi, sa Alva.`}</p>
			</span>
		),
		questions: [
			{
				id: 4,
				text: 'Vad känner Loppan under samtalet med Alva?',
				choices: [
					{
						letter: 'a',
						identity: 'd',
						choice: 'Att det är skönt att ha börjat prata med en av de nya klasskamraterna.',
					},
					{
						letter: 'b',
						identity: 'a',
						choice: 'Att det är jättekul och lätt att börja i en ny klass.',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'Att Alva verkar dum.',
					},
					{
						letter: 'd',
						identity: 'b',
						choice: 'Att det är roligt att prata med Johanna.',
					},
				],
			},
		],
	},

	{
		para: (
			<span>
				<h2 className="text-center">{`5 av 8`}</h2>
			</span>
		),
		questions: [
			{
				id: 5,
				text: 'Hur vet Alva att de ska börja?',
				choices: [
					{
						letter: 'a',
						identity: 'c',
						choice: 'För att Loppan säger det.',
					},
					{
						letter: 'b',
						identity: 'b',
						choice: 'För att de andra klasskamraterna ropar det.',
					},
					{
						letter: 'c',
						identity: 'a',
						choice: 'För att en lärare ringer i en bjällra.',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'För att en lärare ropar på dem.',
					},
				],
			},
		],
	},
	{
		para: (
			<span>
				<h2 className="text-center">{`6 av 8`}</h2>
				<p>{`Vi gick bredvid varandra in i skolan.`}</p>
				<p>{`- Jag måste gå på toa, sa Alva. Hittar du till klassrummet?`}</p>
				<p>{`- Det tror jag nog, sa jag.`}</p>
				<p>{`Mamma och jag hade ju varit här igår och träffat Johanna i klassrummet.`}</p>
				<p>{`Jag hängde av mig jackan i kapprummet utanför innan jag gick in.`}</p>
			</span>
		),
		questions: [
			{
				id: 6,
				text: 'Hur kan Loppan hitta till det nya klassrummet?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'För att Alva visar vägen.',
					},
					{
						letter: 'b',
						identity: 'd',
						choice: 'För att det finns ett stort kapprum.',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'För att Johanna kommer och möter Loppan.',
					},
					{
						letter: 'd',
						identity: 'b',
						choice: 'För att Loppan varit i klassrummet tidigare med sin mamma.',
					},
				],
			},
		],
	},

	{
		para: (
			<span>
				<h2 className="text-center">{`7 av 8`}</h2>
				<p>
					{`Alva stod och pratade med en kille vid en av bänkarna. Jag gick fram
                    till dem.`}
				</p>
				<p>{`- Skulle inte du gå på toa?`}</p>
				<p>{`- Va?, frågade hon.`}</p>
				<p>{`- Du sa ju att du skulle...`}</p>
				<p>
					{`Mina kinder brände som eld och min röst lät så där ohjälpligt löjligt 
                    pipig igen. Jag tystnade eftersom jag inte ville upprepa ”gå på toa”. 
                    Helt socialt missanpassad var jag inte även om Alva nog tänkte det 
                    för hon stirrade på mig som om jag var en utomjording.`}
				</p>
			</span>
		),
		questions: [
			{
				id: 7,
				text: 'Vad känner Loppan?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'Självsäkerhet.',
					},
					{
						letter: 'b',
						identity: 'b',
						choice: 'Sorg.',
					},
					{
						letter: 'c',
						identity: 'd',
						choice: 'Genans.',
					},
					{
						letter: 'd',
						identity: 'c',
						choice: 'Toanödighet.',
					},
				],
			},
		],
	},

	{
		para: (
			<span>
				<h2 className="text-center">{`8 av 8`}</h2>
				<p>{`- Du hittade, sa en röst bakom mig. Jag vände mig om och där stod Alva.`}</p>
				<p>{`Jag tittade från den ena Alva till den andra och nu såg jag att de hade olika tröjor men annars var de precis lika.`}</p>
				<p>{`- Det är inte första gången nån tar fel, fnissade killen.`}</p>
				<p>{`Vi började skratta allihop.`}</p>
			</span>
		),
		questions: [
			{
				id: 8,
				text: 'Vad menar killen?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'Att det finns två som heter Alva i klassen.',
					},
					{
						letter: 'b',
						identity: 'b',
						choice: 'Att Loppan har gått in i fel klassrum',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'Att Alva har två tröjor på sig.',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'Att det kan vara lätt att blanda ihop nya klasskamrater.',
					},
				],
			},
		],
	},
];
export const TextGrade5 = [
	{
		para: (
			<span>
				<h2 className="text-center">{`Vikarien`}</h2>
				<p>{`Malte och Robin mötte mig på skolgården. Maltes ögon tindrade som ett barns i väntan på jultomten.`}</p>
				<p>{`- Vi ska ha vikarie idag, utlyste han triumferande.`}</p>
				<p>{`- Nu blir det lattjo, inflikade Robin med ett brett flin.`}</p>
				<p>{`Jag fnissade och tänkte på förra gången vi hade vikarie när Malte hade satt igång brandlarmet så att hela skolan börjat tjuta.`}</p>
				<p>{`Det var ett öde värre än döden att vara vikarie för vår klass.`}</p>
			</span>
		),
		questions: [
			{
				id: 1,
				text: 'När hade Malte satt igång brandlarmet?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'När hela skolan börjat tjuta.',
					},
					{
						letter: 'b',
						identity: 'c',
						choice: 'När klassen hade vikarie förra gången.',
					},
					{
						letter: 'c',
						identity: 'b',
						choice: 'När deras vanliga lärare höll i lektionen.',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'När det börjat brinna på skolan.',
					},
				],
			},
		],
	},
	{
		para: (
			<span>
				<h2 className="text-center">{`2 av 8`}</h2>
			</span>
		),
		questions: [
			{
				id: 2,
				text: 'Hur är det att vara vikarie i deras klass?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'Mycket svårt.',
					},
					{
						letter: 'b',
						identity: 'b',
						choice: 'Lätt.',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'Långtråkigt.',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'Skojigt.',
					},
				],
			},
		],
	},
	{
		para: (
			<span>
				<h2 className="text-center">{`3 av 8`}</h2>
				<p>
					{`Rektorn hade så klart gett Malte en praktfull avhyvling efteråt, men 
                    av hans uppsyn att döma hade den inte bitit.`}
				</p>
				<p>{`- Vi har förberett en liten grej, sa Malte och tittade på Robin. Men 
                    Robins blick hade fastnat på parkeringen. Ur en grå Volvo klev en 
                    kille med rött skägg och stora kängor.`}</p>
			</span>
		),
		questions: [
			{
				id: 3,
				text: 'Vem tittar Robin på?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'Malte som gått till parkeringen.',
					},
					{
						letter: 'b',
						identity: 'c',
						choice: 'En klasskamrat bakom Malte.',
					},
					{
						letter: 'c',
						identity: 'd',
						choice: 'Killen som kliver ur bilen på parkeringen.',
					},
					{
						letter: 'd',
						identity: 'b',
						choice: 'En tjej som passerar tätt förbi dem.',
					},
				],
			},
		],
	},
	{
		para: (
			<span>
				<h2 className="text-center">{`4 av 8`}</h2>
				<p>{`- Det är ju Kenny Berg!`}</p>
				<p>{`Kenny Berg är karatetränare, med svart bälte och flera svenska 
                mästerskapstitlar i sin ryggsäck. Vi hade börjat i nybörjargruppen i 
                höstas och är det nåt Malte drömmer om så är det att hamna i den 
                avancerade gruppen som Kenny leder.`}</p>
			</span>
		),
		questions: [
			{
				id: 4,
				text: 'Vad står det att Malte längtar efter?',
				choices: [
					{
						letter: 'a',
						identity: 'b',
						choice: 'Att bli karatetränare.',
					},
					{
						letter: 'b',
						identity: 'a',
						choice: 'Att få en likadan ryggsäck som Kenny.',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'Att Kenny ska bli ledare för nybörjargruppen.',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'Att börja i den avancerade karategruppen.',
					},
				],
			},
		],
	},
	{
		para: (
			<span>
				<h2 className="text-center">{`5 av 8`}</h2>
				<p>{`Kenny gick över skolgården och in i skolan.`}</p>
				<p>{`Maltes leende hade slocknat.`}</p>
				<p>{`- Det kan väl inte vara han som...?`}</p>
				<p>{`- Kanske, sa Robin.`}</p>
				<p>{`- Skit!, sa Malte. Kom!`}</p>
				<p>{`De löpte mot skolan och jag följde hack i häl.`}</p>
				<p>{`Utanför vårt klassrum stod Kenny och pratade med Kerstin, som är 
                    lärare för den andra femman.`}</p>
			</span>
		),
		questions: [
			{
				id: 5,
				text: 'Vem pratar Kenny med?',
				choices: [
					{
						letter: 'a',
						identity: 'c',
						choice: 'Med ett par lärare.',
					},
					{
						letter: 'b',
						identity: 'd',
						choice: 'Med Malte.',
					},
					{
						letter: 'c',
						identity: 'b',
						choice: 'Med en elev på skolan.',
					},
					{
						letter: 'd',
						identity: 'a',
						choice: 'Med en lärare på skolan.',
					},
				],
			},
		],
	},
	{
		para: (
			<span>
				<h2 className="text-center">{`6 av 8`}</h2>
				<p>{`Kenny vilade handen på dörrhandtaget.`}</p>
				<p>{`- Tja killar!, hälsade han.`}</p>
				<p>{`- Hej!, hälsade vi i kör.`}</p>
				<p>{`- Öppna inte dörren, sa Malte.`}</p>
				<p>{`- Varför inte?, frågade Kevin förvånat. Men istället för att lyssna på 
                Maltes varning drog han upp dörren varpå vad som såg ut att vara 
                minst ett kilo finmalet vetemjöl regnade ner över honom.`}</p>
			</span>
		),
		questions: [
			{
				id: 6,
				text: 'Vad försöker Malte varna Kevin för?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'Att dörren är låst.',
					},
					{
						letter: 'b',
						identity: 'c',
						choice: 'Att klassen kommer vara busig under dagen.',
					},
					{
						letter: 'c',
						identity: 'b',
						choice: 'Att han kommer att få mjöl över sig.',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'Ingenting, Malte bara skämtar.',
					},
				],
			},
		],
	},
	{
		para: (
			<span>
				<h2 className="text-center">{`7 av 8`}</h2>
				<p>{`- Jag hörde att nån gjort en fälla här, sa Malte. Det var därför jag sa 
                att du inte skulle öppna dörren. Vi ville inte att du skulle...`}</p>
				<p>{`Kerstin tittade bistert på Malte.`}</p>
				<p>{`- Nån hade gjort en fälla? Vem skulle det vara, som kom på nåt sånt?`}</p>
				<p>{`- Freja och Neda, sa Malte kvickt.`}</p>
				<p>{`Kerstin fick en rynka mellan ögonbrynen och rösten lät som en 
                skarpladdad pistol när hon deklarerade:`}</p>
				<p>{`- Nej du, nu blir det en träff med rektorn för dig.`}</p>
				<p>{`Kevin skakade på sig så att det finmalda mjölet föll runt honom 
                innan han tog till orda.`}</p>
				<p>{`- Jag tror vi kan lösa detta på ett bättre sätt, sa han. Jag är säker på 
                att Malte ångrar sina synder och vill städa upp här och sen sköta sig 
                exemplariskt resten av dagen, eller hur?`}</p>
				<p>{`Han tittade på Malte som blivit en tomat i ansiktet.`}</p>
				<p>{`- Förlåt, sa Malte. Det var dumt gjort.`}</p>
			</span>
		),
		questions: [
			{
				id: 7,
				text: 'Varför vill Kerstin skicka Malte till rektorn?',
				choices: [
					{
						letter: 'a',
						identity: 'd',
						choice: 'För att hon tror att Malte ljuger.',
					},
					{
						letter: 'b',
						identity: 'a',
						choice: 'För att hon vet att Malte talar sanning.',
					},
					{
						letter: 'c',
						identity: 'b',
						choice: 'För att Maltes föräldrar redan är där.',
					},
					{
						letter: 'd',
						identity: 'c',
						choice: 'För att rektorn behöver hjälp.',
					},
				],
			},
		],
	},
	{
		para: (
			<span>
				<h2 className="text-center">{`8 av 8`}</h2>
			</span>
		),
		questions: [
			{
				id: 8,
				text: 'Varför säger Kevin att Malte ska städa?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'För att han tänker att Malte gillar att städa.',
					},
					{
						letter: 'b',
						identity: 'b',
						choice: 'För att Malte är så skötsam.',
					},
					{
						letter: 'c',
						identity: 'd',
						choice: 'För att det var Malte som gillrat fällan.',
					},
					{
						letter: 'd',
						identity: 'c',
						choice: 'För att rektorn sagt att Malte ska göra det.',
					},
				],
			},
		],
	},
];
export const TextGrade6 = [
	{
		para: (
			<span>
				<h2 className="text-center">{`Lina❤️`}</h2>
				<p>
					{`Novemberskymningen la sig stillsamt utanför fönstret medan jag 
                    kämpade på med min matteläxa då mobilens ringsignal fick mig att 
                    lyfta blicken från räknehäftet. Nej, det kunde inte stämma! Jo, där 
                    stod Linas namn med det röda hjärtat bakom.`}
				</p>
			</span>
		),
		questions: [
			{
				id: 1,
				text: 'Vilken tid på dygnet ringer Lina.',
				choices: [
					{
						letter: 'a',
						identity: 'c',
						choice: 'På eftermiddagen när det börjat bli mörkt.',
					},
					{
						letter: 'b',
						identity: 'a',
						choice: 'På morgonen innan solen gått upp helt.',
					},
					{
						letter: 'c',
						identity: 'b',
						choice: 'Det sägs inte av texten vilken tid på dygnet hon ringer.',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'På natten.',
					},
				],
			},
		],
	},
	{
		para: (
			<span>
				<h2 className="text-center">{`2 av 8`}</h2>
				<p>{`Jag drog efter andan och tryckte på svara.`}</p>
				<p>{`-Hallå!`}</p>
				<p>{`-Hej Lo! Vill du ses imorgon eftermiddag och ta en fika eller nåt?`}</p>
				<p>{`Det bubblade i hela min kropp samtidigt som jag hade svårt att tro 
                    att jag hört rätt. Även om jag skrivit in Linas nummer med ett hjärta i
                    min mobil var sanningen att Lina och jag aldrig hade pratat, knappt ens sagt hej och nu ringde hon och frågade om vi skulle ses. Kunde 
                    det vara kärlekens magiska kraft? I så fall var det bäst att åka med.`}</p>
				<p>{`- Det gör jag gärna!`}</p>
				<p>{`- Då ses vi! Hejdå!`}</p>
			</span>
		),
		questions: [
			{
				id: 2,
				text: 'Var har Lo skrivit Linas telefonnummer?',
				choices: [
					{
						letter: 'a',
						identity: 'b',
						choice: 'På en lapp.',
					},
					{
						letter: 'b',
						identity: 'c',
						choice: 'På sin arm.',
					},
					{
						letter: 'c',
						identity: 'a',
						choice: 'I sin telefonbok i mobilen.',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'Lo vet inte vilket nummer Lina har.',
					},
				],
			},
		],
	},
	{
		para: (
			<span>
				<h2 className="text-center">{`3 av 8`}</h2>
				<p>{`När jag kom till skolan nästa dag såg jag Lina stå vid trappan in till 
                    sexornas paviljong med några tjejer i sin klass. Jag repade allt mod 
                    jag kunde uppbåda och släntrade fram.`}</p>
			</span>
		),
		questions: [
			{
				id: 3,
				text: 'Vad känner Lo för att gå fram till Lina?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'Lo ser inte fram emot att prata med Lina.',
					},
					{
						letter: 'b',
						identity: 'b',
						choice: 'Lo är trött men vill ändå prata med Lina.',
					},
					{
						letter: 'c',
						identity: 'd',
						choice: 'Lo är nervös och behöver vara modig för att göra det.',
					},
					{
						letter: 'd',
						identity: 'c',
						choice: 'Lo känner inte det är någon stor grej alls att prata med Lina.',
					},
				],
			},
		],
	},
	{
		para: (
			<span>
				<h2 className="text-center">{`4 av 8?`}</h2>
				<p>{`- Hej Lina!`}</p>
				<p>{`Hon gav mig en svårtolkad blick.`}</p>
				<p>{`- Hej.`}</p>
				<p>{`- Hur mår du?`}</p>
				<p>{`- Öhh jo bra.`}</p>
				<p>
					{`Hennes röst lät annorlunda, liksom lite mörkare och med mer dialekt
                    än igår när hon ringt. Bristen på respons och de andra tjejernas 
                    ansiktsuttryck, som svämmade över av nåt som var misstänkt likt 
                    återhållet skratt fick mig att bli osäker. Kanske ville Lina inte prata 
                    med mig när hennes kompisar var med?`}
				</p>
				<p>
					{`-Vi ses efter skolan, sa jag och förberedde mig på att gå eftersom jag
                    inte ville vara en påträngande person.`}
				</p>
			</span>
		),
		questions: [
			{
				id: 4,
				text: 'Varför förbereder Lo sig på att gå?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'För att Lo tycker Lina är tråkig att prata med.',
					},
					{
						letter: 'b',
						identity: 'd',
						choice: 'För att Lo vill göra gott intryck på Lina.',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'För att Lo hellre vill vara med sina klasskompisar.',
					},
					{
						letter: 'd',
						identity: 'b',
						choice: 'För att Lo vill vara efterhängsen.',
					},
				],
			},
		],
	},
	{
		para: (
			<span>
				<h2 className="text-center">{`5 av 8`}</h2>
				<p>
					{`- adå? Ska vi träffas efter skolan?, sa Lina förvånat.`}
				</p>
				<p>
					{`Ett gällt skratt fick mig att vända mig om och se Lovis och Jasmin 
                    från min klass en bit bort.`}
				</p>
				<p>{`- Du ringde ju igår ..., började jag.`}</p>
				<p>{`Lina såg ut som ett frågetecken. Jag höll mobilen nära mig så att hon
                    inte kunde se skärmen, för jag skulle avlida på sekunden om hon 
                    upptäckte hjärtat. Jag läste upp hennes nummer högt.`}</p>
			</span>
		),
		questions: [
			{
				id: 5,
				text: 'Vem är det som skrattar gällt?',
				choices: [
					{
						letter: 'a',
						identity: 'c',
						choice: 'Lina.',
					},
					{
						letter: 'b',
						identity: 'b',
						choice: 'Tjejerna som Lina pratat med från sin klass.',
					},
					{
						letter: 'c',
						identity: 'a',
						choice: 'Två klasskamrater till Lo.',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'Två klasskamrater till Lina.',
					},
				],
			},
		],
	},
	{
		para: (
			<span>
				<h2 className="text-center">{`6 av 8`}</h2>
			</span>
		),
		questions: [
			{
				id: 6,
				text: 'Varför håller Lo mobilen nära sig?',
				choices: [
					{
						letter: 'a',
						identity: 'b',
						choice: 'För att Lina inte ska se hjärtat efter sitt namn i Los mobil.',
					},
					{
						letter: 'b',
						identity: 'a',
						choice: 'Det står inte i texten.',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'För att Lina inte ska knycka mobilen.',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'För att Lo har dålig syn och inte kan läsa numret annars.',
					},
				],
			},
		],
	},
	{
		para: (
			<span>
				<h2 className="text-center">{`7 av 8`}</h2>
				<p>
					{`- Jo, det är mitt nummer, svarade Lina och kastade en mörk blick mot
                    Lovis och Jasmin. Sen räckte hon fram sin mobil så att jag kunde se 
                    hennes samtalslistor.`}
				</p>
				<p>{`- Där är mitt nummer, du ringde mig igår, sa jag med svag röst.`}</p>
				<p>{`-Uppenbarligen! Vid halv sju när jag var på träning och mobilen låg i väskan.`}</p>
				<p>{`Hon vände sig mot Lovis och Jasmin.`}</p>
				<p>{`-Så taskigt av er!, skrek hon.`}</p>
				<p>{`- Mäh det var väl kul?!, sa Lovis.`}</p>
				<p>{`Lina fnös innan hon vände sig tillbaka till mig.`}</p>
				<p>{`- Bry dig inte om dem! Jag ses gärna efter skolan och då kan vi ju exempelvis prata om hur sviniga vissa av dina klasskamrater är.`}</p>
				<p>{`Jag nickade med halvöppen mun och försökte förstå vad som just hänt.`}</p>
			</span>
		),
		questions: [
			{
				id: 7,
				text: 'Varför blev Lina arg?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'För att Lo ljög och sa att Lina hade ringt till henne.',
					},
					{
						letter: 'b',
						identity: 'b',
						choice: 'För att Lo hälsade på henne fast de inte känner varandra.',
					},
					{
						letter: 'c',
						identity: 'd',
						choice: 'För att Lovis och Jasmin hade tjuvringt Lo och låtsats att det var Lina som ringde.',
					},
					{
						letter: 'd',
						identity: 'c',
						choice: 'För att Lovis och Jasmin skrattade åt henne.',
					},
				],
			},
		],
	},
	{
		para: (
			<span>
				<h2 className="text-center">{`8 av 8`}</h2>
			</span>
		),
		questions: [
			{
				id: 8,
				text: 'Står det i texten vilket kön Lo har?',
				choices: [
					{
						letter: 'a',
						identity: 'a',
						choice: 'Det står att Lo är en kille.',
					},
					{
						letter: 'b',
						identity: 'b',
						choice: 'Det står att Lo är ickebinär.',
					},
					{
						letter: 'c',
						identity: 'c',
						choice: 'Det står att Lo är en tjej.',
					},
					{
						letter: 'd',
						identity: 'd',
						choice: 'Det står inte vilket kön Lo har.',
					},
				],
			},
		],
	},
];

export const text = (
	<span>
		<h2 className="text-center">{`Vendepunktet`}</h2>
		<p>
			{`«Noah har mange venner.
			Abdi har mange venner han også. Ikke alle har venner.
			Pål har ingen venner. Pål er mye alene. Noah går på besøk til Pål i dag.
			Han er snill og flink. Det er ingen grunn til at Pål ikke skal ha venner.
			Pål kan bli venn med Noah. Noah vil ha nye venner. Noen samler på ting. Noah samler på venner.
			Pål har mange spill. Han har også puslespill, brio og dam.`}
		</p>
		<p>
			{`Mamma hadde blitt oppringt av senterlederen mens jeg var på vei 
			hjem. Det var forsvunnet ting fra butikken jeg hadde vært på. Jeg 
			er akkurat kommet inn døra hjemme når jeg blir konfrontert med 
			anklagene. «Jeg har ikke tatt noe», svarer jeg. «Det er bare å 
			sjekke alt jeg har på meg. Jeg har ikke tatt noe».`}
		</p>
		<p>
			{`Og jeg vet at de ikke vil finne noe hverken i skolesekken eller i 
			lommene. Mamma tømmer ut alt innholdet i sekken mens pappa saumfarer 
			jakkelommene med brå bevegelser. «Hvor har du fått tak i denne?» 
			spør mamma mens hun tar ut én og én ting fra sminkebagen min. «Den 
			der kjøpte jeg for tre måneder siden, minst. Ser du ikke at den 
			snart er oppbrukt?» svarer jeg. Og sånn holder vi på til alt fra 
			sminke til gymtøy er gjennomgått. Ingenting av det var nytt, og jeg 
			kunne gjøre rede for alt. Mamma og pappa veksler blikk, og jeg aner 
			en viss usikkerhet hos dem.`}
		</p>
		<p>
			{`«Er det noe du vil fortelle oss? Nå har du sjansen til å fortelle 
			oss sannheten. Vi vil høre den fra deg selv, ikke fra andre, om du 
			har gjort noe dumt» sier pappa. «Du ser jo selv at jeg ikke har tatt 
			noe», svarer jeg. «Senterlederen sier at de har sterk mistanke om at 
			du har stålet. De ringer jo ikke bare på tull og påstår noe så 
			alvorlig? Om du lyver for oss nå, så er det et alvorlig 
			tillitsbrudd» fortsetter han.`}
		</p>
		<p>
			{`Nå kjenner jeg ikke bare på angsten, men også på fortvilelsen over 
			at de ikke tror på meg, enda de ikke finner noe. At de tror mer på 
			andre enn på meg. Dette er en helt ny opplevelse. Jeg vil gråte, men 
			er samtidig så redd at gråten ikke kommer ut.`}
		</p>
		<p>
			{`«Senterlederen vil gjerne snakke med oss, så vi skal dra dit nå», 
			sier mamma. «Men skal vi ikke spise først?» spør jeg enda jeg ikke 
			er sulten i det hele tatt. Middagslukten fra kjøkkenet gir likevel 
			håp om at alt kan settes på pause. Jeg føler meg uvel. Selv ikke 
			tikka masala, yndlingsretten min, frister akkurat nå. «Middagen får 
			vente», svarer mamma. «Vi må få denne saka ut av verden så fort som 
			mulig».`}
		</p>
		<p>
			{`Det er merkelig å kjøre samme veien tilbake som jeg for under en 
			time siden hadde kjørt med bussen. Jeg hadde vært en kjapp tur innom 
			kjøpesenteret etter skoletid, og tok en milkshake sammen med noen fra 
			klassen. Det er stille i bilen. Jeg har musikken på lavt så jeg kan 
			høre hvis de snakker seg imellom. Men de veksler ikke et ord. Jeg 
			prøver å lese ansiktet til mamma i sidespeilet, mens jeg kan studere 
			pappa mer direkte fra baksetet. Han ser veldig alvorlig og 
			konsentrert ut der han kjører. Han er sikkert veldig lettet for at 
			de ikke fant noe. Begge tenker sikkert på hva de skal si på møtet.`}
		</p>
		<p>
			{`Inne på senteret går vi inn ei grønn dør som er skiltet med 
			«Ledelse». Jeg har knapt lagt merke til denne tidligere, enda jeg 
			har gått forbi der flere ganger på vei til toalettet. Ei 
			securitasvakt spør om vi skal møte senterlederen, og viser oss vei. 
			Vi kommer inn i en liten korridor med mange dører, og i enden står 
			ei dør på gløtt. Inne på kontoret sitter to damer og en annen 
			securitasvakt. Hun ene har jeg aldri sett på ordentlig, bare i 
			media. Hun har langt lyst hår, knallrøde negler og lepper, og er 
			stilig kledd. Hun andre kjenner jeg igjen fra butikken. Hun har 
			brunt hår som er samlet i en lang hestehale, og er ganske pen. 
			Securitasvakta kjenner jeg også igjen. Han bruker noen ganger å gå 
			rundt, og noen ganger bruker han å sitte i service-skranken midt 
			inne i senteret i 1.etasjen.`}
		</p>
		<p>
			{`Vi setter oss rundt et stort møtebord, og senterlederen innleder 
			med å fortelle at det alltid er like trist når unge mennesker blir 
			tatt for butikktyveri. «Vi anmelder samtlige fordi det ellers vil 
			være umulig å velge hvem som skal anmeldes og hvem som skal slippe». 
			Butikkdama tar ordet og sier at «Et mammahjerte brister når jeg må 
			anmelde noen som er tatt for første gang. Det er veldig vondt. Men 
			hva skal jeg gjøre? Jeg må selv betale for å få varer inn til 
			butikken min, og jeg må fortsatt betale lønn til de ansatte og 
			betale for leie av butikklokalene – selv om folk stjeler og jeg 
			taper inntekt. Det er så jævlig urettferdig at jeg skal lide for 
			at enkelte ikke skjønner forskjellen på mitt og ditt».  `}
		</p>
		<p>
			{`Nå er det securitasvakten som tar ordet: «Etter mange års erfaring 
			kan vi se potensielle butikktyver. Det handler om hvordan de 
			oppfører seg i butikken, selv om de tror at de oppfører seg helt 
			normalt. Og mens gutter typisk stjeler alene, så stjeler jentene 
			når de er sammen med andre. Et vanlig eksempel er når tre venninner 
			er inne samtidig og to gjør hva de kan for å avlede oppmerksomheten 
			mens den tredje stjeler. Da vet vi at det er en vel gjennomtenkt 
			handling». Senterlederen overtar ordet, og veksler mellom å se på 
			meg og foreldrene mine: «Noen ganger blir én person i en vennegjeng 
			tatt. Men vi vet hvem de andre er. Da vil også de andre vennene bli 
			møtt med mistenksomhet neste gang de er i butikkene våre, selv om de 
			er uskyldige. Alle blir stemplet dersom de er i venneflokken til en 
			butikktyv».`}
		</p>
		<p>
			{`Jeg aner nå et lite smil hos mamma. Hun vet at dette ikke angår 
			meg. Jeg opptrer ikke i noen gjeng. Selv om vi har bodd på dette 
			stedet i to år nå, har det vært vanskelig å få nye venner. Dronninga 
			i klassen og hoffet hennes bitch-blikket meg ganske lenge, og guttene 
			er noen skikkelige drama queens som sprer falske rykter om folk. 
			Heldigvis har det blitt bedre i det siste, og jeg har liksom blitt 
			mer akseptert. I morgen kveld skal jeg til og med få komme hjem på 
			filmkveld hos dronninga. Men jeg savner gammelklassen.`}
		</p>
		<p>
			{`«Uansett om den vi fersker er fylt 15 år eller ikke, så anmelder 
			vi saka til politiet», fortsetter senterlederen. «Du blir da 
			registrert som anmeldt, og må møte hos politiet. Barnevernet blir 
			ofte også kontaktet».`}
		</p>
		<p>
			{`Det virker som de har snakket om dette mange ganger før. 
			Senterlederen, butikksjefen og securitasvakten snakker ikke i munnen 
			på hverandre, men de overtar for hverandre som i et stafettløp. Jeg 
			hører på måten mamma og pappa puster på at de har tenkt å si noe nå, 
			de også. Men før noen av dem kommer så langt, fortsetter 
			securitasvakten mens han ser på meg: «Hvis du er fylt 15 kan du bli 
			straffet med bøter. Da blir du registrert som straffet person, men i 
			enkelte tilfeller kan du være heldig å bare få påtaleunnlatelse. Det 
			vil si at du er skyldig, men ikke får straff denne gangen».`}
		</p>
		<p>
			{`Senterlederen ser på pappa, selv om det er meg hun snakker til: 
			«Husk at hvis du blir tatt for å stjele kan også mulighetene dine 
			videre i livet bli begrenset. Du kan få utsettelse på når du kan ta 
			mopedførerbeviset og førerkortet, i tillegg kan du få problemer med å 
			få jobber eller studier som krever sikkerhetsklarering». Så flytter 
			hun blikket til meg og spør: «Er du fylt 15?».`}
		</p>
		<p>
			{`Nå har pappa fått nok. Han vrir seg i stolen og kremter høyt. 
			Stemmen hans skjelver når han begynner å prate, og det gjør den bare 
			når han er sint. «Og dere er kanskje også kjent med at det er 
			straffbart å fremsette falske beskyldninger og fabrikkere bevis? Er 
			det ikke tre år som er strafferammen for slikt? Det skal jeg nok 
			finne ut av». Det blir plutselig en annen temperatur i rommet. 
			Senterlederen prøver å si noe, men blir avbrutt av pappa. «Vi 
			ransaket skolesekken og jakkelommene hennes med det samme hun kom 
			inn døra hjemme. Fra vi ble varslet og til hun kom hjem gikk det 
			veldig kort tid, og hun hadde ikke hatt sjans til å gjemme noe. Og 
			bare så det er sagt så fant vi ingenting som ikke er hennes. 
			Ingenting! Derfor er det veldig merkelig å sitte og høre på alt dere 
			lirer av dere – uten at dere har noe som helst belegg for det dere 
			beskylder henne for».`}
		</p>
		<p>
			{`Jeg kjenner på en enorm lettelse. Mamma og pappa tror på meg 
			likevel. Og pappa har jo helt rett. Det er ingen som har tatt meg 
			med stjålne ting`}
		</p>
		<p>
			{`Selv om det ennå er seint på ettermiddagen, føles det som at det 
			er seint på kvelden. Det har skjedd så mye. Jeg rekker å kjenne på 
			at jeg er sulten, at jeg gleder meg til å komme hjem og spise, og at 
			det er godt at det er helg. Men så kommer Securitasvakta med ordene 
			som får hele verden min til å falle i grus. `}
		</p>
		<p>
			{`«Vi har bevis. Ikke bare fra i dag, men også fra to tidligere 
			tilfeller, og i ulike butikker. Som sagt så stjeler jenter helst i 
			flokk. Men det har ikke skjedd i dette tilfellet. Vi har hatt 
			mistanke over tid, og mistanken vår er blitt fanget opp og bekreftet 
			gjennom opptak fra våre overvåkningskamera».`}
		</p>
		<p>
			{`Kroppen er paralysert. Ansiktet er helt numment, som om jeg har 
			mistet alt blodet. Mamma og pappa ser på meg med vantro blikk. 
			Stemmene i rommet er langt borte. Det er som at jeg ikke skjønner 
			hva som skjer rundt meg. Jeg kjenner på skammen både for det jeg har 
			gjort og for at jeg har løyet til dem. Og oppi alt kjenner jeg på en 
			lettelse.`}
		</p>
	</span>
);
