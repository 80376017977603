import React from 'react';

import strings from '../../utils/strings';

import {updateObject} from '../../utils/reducerUtils';
import FinishSound from '../../components/FinishSound';

import classNames from 'classnames';
import Countdown from '../../components/Countdown';

import {VARIATION} from '../../constants';
import * as textSe from './languages/se-text';

const textObj = {
	se: textSe,
};

const getTestTextForGrade = (grade) => {
	let languageText;
	let textForGrade;
	switch (grade) {
		case 1:
			languageText = textObj[strings.getLanguage()];
			textForGrade = languageText.TextGrade1;
			return textForGrade;

		case 2:
			languageText = textObj[strings.getLanguage()];
			textForGrade = languageText.TextGrade2;
			return textForGrade;

		case 3:
			languageText = textObj[strings.getLanguage()];
			textForGrade = languageText.TextGrade3;
			return textForGrade;

		case 4:
			languageText = textObj[strings.getLanguage()];
			textForGrade = languageText.TextGrade4;
			return textForGrade;

		case 5:
			languageText = textObj[strings.getLanguage()];
			textForGrade = languageText.TextGrade5;
			return textForGrade;

		case 6:
			languageText = textObj[strings.getLanguage()];
			textForGrade = languageText.TextGrade6;
			return textForGrade;
	}
};

class Test extends React.Component {
	constructor(props) {
		super(props);
		const grade = Number(
			this.props.candidateGrade.grade || this.props.candidateGrade
		);
		this.allTestMaterial = getTestTextForGrade(grade);

		const variation = VARIATION;
		const language = strings.getLanguage();

		this.taskIsOver =
			language === 'se'
				? require(`../../assets/sounds/${variation}/${language}/DictatOverBluffStart.mp3`)
				: null;

		const results = {};

		for (let i = 0; i < this.allTestMaterial.length; i++) {
			const questions = this.allTestMaterial[i].questions;
			for (let j = 0; j < questions.length; j++) {
				const id = questions[j].id;
				results[id] = '';
			}
		}

		this.state = {
			results,
			timeRanOut: false,
			renderFinalSound: false,
		};
	}

	setResults(id, value) {
		const {results} = this.state;

		this.setState({
			results: updateObject(results, {
				[id]: value,
			}),
		});
	}

	render() {
		const {onNextStep} = this.props;

		const {results, timeRanOut} = this.state;
		const allResultsHasValues = Object.keys(results).every(
			(key) => results[key]
		);
		const isNextButtonVisible = allResultsHasValues || timeRanOut;
		return (
			<div className="lit-test lit-leseproven">
				<div className="lit-infobar">
					<Countdown
						duration={720}
						onComplete={() => {
							this.setState({
								timeRanOut: true,
								renderFinalSound: true,
							});
						}}
					/>
				</div>
				{this.allTestMaterial.map((testMaterial, index) => {
					const {para, questions} = testMaterial;

					return (
						<div key={index}>
							<div className="story">{para} </div>

							<div
								className={classNames({
									'lit-questions': true,
									'time-ran-out': timeRanOut,
								})}
							>
								<div className="questions-intro">
									{/* <h2 className="lit-page-title">
										Spørsmål til teksten
									</h2> */}
									<p>Trykk på riktig alternativ</p>
								</div>
								{questions.map((question) => {
									const {text, id} = question;
									const currentQuestionValue = results[id];
									const choices = question.choices;

									return (
										<div className="lit-question" key={id}>
											<span className="lit-input__label lit-input__label--count">
												{id}
											</span>
											<h4 className="question__text">
												{text}
											</h4>
											<div className="choices">
												{choices.map((choice) => {
													const choiceLetter =
														choice.letter;
													const mark =
														choice.identity;
													const idChoice =
														id + choiceLetter;
													return (
														<span
															className="lit-choice"
															key={idChoice}
														>
															<input
																checked={
																	currentQuestionValue ===
																	mark
																}
																className="lit-choice__field"
																disabled={
																	timeRanOut
																}
																id={idChoice}
																onChange={() =>
																	this.setResults(
																		id,
																		mark
																	)
																}
																type="radio"
															/>
															<label
																className="lit-choice__label"
																htmlFor={
																	idChoice
																}
															>
																<div className="checkbox" />
																{choice.choice}
															</label>
														</span>
													);
												})}
											</div>
										</div>
									);
								})}
							</div>
						</div>
					);
				})}

				{(isNextButtonVisible || this.state.renderFinalSound) && (
					<div>
						<span>
							<FinishSound
								renderFinalSound={this.state.renderFinalSound}
								text={strings.Dictation_is_Finished}
								audioSrc={this.taskIsOver}
							/>

							<button
								className="lit-btn lit bg-primary"
								onClick={() => onNextStep(results)}
							>
								{strings.done}
							</button>
						</span>
					</div>
				)}
			</div>
		);
	}
}

export default Test;
