import audio1 from '../../../../assets/sounds/child/se/diktat/SWSCRC6-2.mp3';
import audio2 from '../../../../assets/sounds/child/se/diktat/SWSCRC6-3.mp3';
import audio3 from '../../../../assets/sounds/child/se/diktat/SWSCRC6-4.mp3';
import audio4 from '../../../../assets/sounds/child/se/diktat/SWSCRC6-5.mp3';
import audio5 from '../../../../assets/sounds/child/se/diktat/SWSCRC6-6.mp3';
import audio6 from '../../../../assets/sounds/child/se/diktat/SWSCRC6-7.mp3';
import audio7 from '../../../../assets/sounds/child/se/diktat/SWSCRC6-8.mp3';
import audio8 from '../../../../assets/sounds/child/se/diktat/SWSCRC6-9.mp3';
import audio9 from '../../../../assets/sounds/child/se/diktat/SWSCRC6-10.mp3';
import audio10 from '../../../../assets/sounds/child/se/diktat/SWSCRC6-11.mp3';
import audio11 from '../../../../assets/sounds/child/se/diktat/SWSCRC6-12.mp3';
import audio12 from '../../../../assets/sounds/child/se/diktat/SWSCRC6-13.mp3';
import audio13 from '../../../../assets/sounds/child/se/diktat/SWSCRC6-14.mp3';
import audio14 from '../../../../assets/sounds/child/se/diktat/SWSCRC6-15.mp3';
import audio15 from '../../../../assets/sounds/child/se/diktat/SWSCRC6-16.mp3';
import audio16 from '../../../../assets/sounds/child/se/diktat/SWSCRC6-17.mp3';
import audio17 from '../../../../assets/sounds/child/se/diktat/SWSCRC6-18.mp3';
import audio18 from '../../../../assets/sounds/child/se/diktat/SWSCRC6-19.mp3';
import audio19 from '../../../../assets/sounds/child/se/diktat/SWSCRC6-20.mp3';
import audio20 from '../../../../assets/sounds/child/se/diktat/SWSCRC6-21.mp3';
import audio21 from '../../../../assets/sounds/child/se/diktat/SWSCRC6-22.mp3';
import audio22 from '../../../../assets/sounds/child/se/diktat/SWSCRC6-23.mp3';
import audio23 from '../../../../assets/sounds/child/se/diktat/SWSCRC6-24.mp3';
import audio24 from '../../../../assets/sounds/child/se/diktat/SWSCRC6-25.mp3';
import audio25 from '../../../../assets/sounds/child/se/diktat/SWSCRC6-26.mp3';


const audioFiles = {
	1: audio1,
	2: audio2,
	3: audio3,
	4: audio4,
	5: audio5,
	6: audio6,
	7: audio7,
	8: audio8,
	9: audio9,
	10: audio10,
	11: audio11,
	12: audio12,
	13: audio13,
	14: audio14,
	15: audio15,
	16: audio16,
	17: audio17,
	18: audio18,
	19: audio19,
	20: audio20,
	21: audio21,
	22: audio22,
	23: audio23,
	24: audio24,
	25: audio25,

	

	
};

export default audioFiles;
