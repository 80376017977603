import audio1 from '../../../assets/sounds/child/se/wordComprehension/omgivning.mp3';
import audio2 from '../../../assets/sounds/child/se/wordComprehension/spridning.mp3';
import audio3 from '../../../assets/sounds/child/se/wordComprehension/berattelse.mp3';
import audio4 from '../../../assets/sounds/child/se/wordComprehension/miljo.mp3';
import audio5 from '../../../assets/sounds/child/se/wordComprehension/omvag.mp3';

import audio6 from '../../../assets/sounds/child/se/wordComprehension/vrida.mp3';
import audio7 from '../../../assets/sounds/child/se/wordComprehension/hoppa.mp3';
import audio8 from '../../../assets/sounds/child/se/wordComprehension/stracka.mp3';
import audio9 from '../../../assets/sounds/child/se/wordComprehension/kasta.mp3';
import audio10 from '../../../assets/sounds/child/se/wordComprehension/skruva.mp3';

import audio11 from '../../../assets/sounds/child/se/wordComprehension/addera.mp3';
import audio12 from '../../../assets/sounds/child/se/wordComprehension/dra-ifran.mp3';
import audio13 from '../../../assets/sounds/child/se/wordComprehension/gangra.mp3';
import audio14 from '../../../assets/sounds/child/se/wordComprehension/lagga-ihop.mp3';
import audio15 from '../../../assets/sounds/child/se/wordComprehension/dela.mp3';

import audio16 from '../../../assets/sounds/child/se/wordComprehension/forsaga.mp3';
import audio17 from '../../../assets/sounds/child/se/wordComprehension/avsloja.mp3';
import audio18 from '../../../assets/sounds/child/se/wordComprehension/prata-forst.mp3';
import audio19 from '../../../assets/sounds/child/se/wordComprehension/salja.mp3';
import audio20 from '../../../assets/sounds/child/se/wordComprehension/ljuga.mp3';

import audio21 from '../../../assets/sounds/child/se/wordComprehension/tabbe.mp3';
import audio22 from '../../../assets/sounds/child/se/wordComprehension/linjal.mp3';
import audio23 from '../../../assets/sounds/child/se/wordComprehension/miniraknare.mp3';
import audio24 from '../../../assets/sounds/child/se/wordComprehension/stubbe.mp3';
import audio25 from '../../../assets/sounds/child/se/wordComprehension/misstag.mp3';

import audio26 from '../../../assets/sounds/child/se/wordComprehension/arva.mp3';
import audio27 from '../../../assets/sounds/child/se/wordComprehension/mala.mp3';
import audio28 from '../../../assets/sounds/child/se/wordComprehension/glasbit.mp3';
import audio29 from '../../../assets/sounds/child/se/wordComprehension/overta.mp3';
import audio30 from '../../../assets/sounds/child/se/wordComprehension/godsel.mp3';

import audio31 from '../../../assets/sounds/child/se/wordComprehension/orolig.mp3';
import audio32 from '../../../assets/sounds/child/se/wordComprehension/trott.mp3';
import audio33 from '../../../assets/sounds/child/se/wordComprehension/radd.mp3';
import audio34 from '../../../assets/sounds/child/se/wordComprehension/sur.mp3';
import audio35 from '../../../assets/sounds/child/se/wordComprehension/ledsen.mp3';

import audio36 from '../../../assets/sounds/child/se/wordComprehension/lojal.mp3';
import audio37 from '../../../assets/sounds/child/se/wordComprehension/rum.mp3';
import audio38 from '../../../assets/sounds/child/se/wordComprehension/olaglig.mp3';
import audio39 from '../../../assets/sounds/child/se/wordComprehension/palitlig.mp3';
import audio40 from '../../../assets/sounds/child/se/wordComprehension/intresserad.mp3';

import audio41 from '../../../assets/sounds/child/se/wordComprehension/kompensation.mp3';
import audio42 from '../../../assets/sounds/child/se/wordComprehension/ersattning.mp3';
import audio43 from '../../../assets/sounds/child/se/wordComprehension/pappershafte.mp3';
import audio44 from '../../../assets/sounds/child/se/wordComprehension/skuld.mp3';
import audio45 from '../../../assets/sounds/child/se/wordComprehension/anfall.mp3';

import audio46 from '../../../assets/sounds/child/se/wordComprehension/ljummen.mp3';
import audio47 from '../../../assets/sounds/child/se/wordComprehension/iskall.mp3';
import audio48 from '../../../assets/sounds/child/se/wordComprehension/vatten.mp3';
import audio49 from '../../../assets/sounds/child/se/wordComprehension/kokhet.mp3';
import audio50 from '../../../assets/sounds/child/se/wordComprehension/halvvarm.mp3';

import audio51 from '../../../assets/sounds/child/se/wordComprehension/ovasen.mp3';
import audio52 from '../../../assets/sounds/child/se/wordComprehension/ovantad.mp3';
import audio53 from '../../../assets/sounds/child/se/wordComprehension/oljud.mp3';
import audio54 from '../../../assets/sounds/child/se/wordComprehension/tystnad.mp3';
import audio55 from '../../../assets/sounds/child/se/wordComprehension/pysande-ljud.mp3';

import audio56 from '../../../assets/sounds/child/se/wordComprehension/bevilja.mp3';
import audio57 from '../../../assets/sounds/child/se/wordComprehension/godkanna.mp3';
import audio58 from '../../../assets/sounds/child/se/wordComprehension/onska.mp3';
import audio59 from '../../../assets/sounds/child/se/wordComprehension/tanka.mp3';
import audio60 from '../../../assets/sounds/child/se/wordComprehension/bestalla.mp3';

import audio61 from '../../../assets/sounds/child/se/wordComprehension/urinera.mp3';
import audio62 from '../../../assets/sounds/child/se/wordComprehension/planera.mp3';
import audio63 from '../../../assets/sounds/child/se/wordComprehension/kissa.mp3';
import audio64 from '../../../assets/sounds/child/se/wordComprehension/klocka.mp3';
import audio65 from '../../../assets/sounds/child/se/wordComprehension/klara-av.mp3';

import audio66 from '../../../assets/sounds/child/se/wordComprehension/asikt.mp3';
import audio67 from '../../../assets/sounds/child/se/wordComprehension/upptackt.mp3';
import audio68 from '../../../assets/sounds/child/se/wordComprehension/missforstand.mp3';
import audio69 from '../../../assets/sounds/child/se/wordComprehension/vattendrag.mp3';
import audio70 from '../../../assets/sounds/child/se/wordComprehension/uppfattning.mp3';

import audio71 from '../../../assets/sounds/child/se/wordComprehension/ntt.mp3';
import audio72 from '../../../assets/sounds/child/se/wordComprehension/liten.mp3';
import audio73 from '../../../assets/sounds/child/se/wordComprehension/sliten.mp3';
import audio74 from '../../../assets/sounds/child/se/wordComprehension/lat.mp3';
import audio75 from '../../../assets/sounds/child/se/wordComprehension/trott.mp3';

import audio76 from '../../../assets/sounds/child/se/wordComprehension/skeptisk.mp3';
import audio77 from '../../../assets/sounds/child/se/wordComprehension/frustrerad.mp3';
import audio78 from '../../../assets/sounds/child/se/wordComprehension/segel.mp3';
import audio79 from '../../../assets/sounds/child/se/wordComprehension/misstnksam.mp3';
import audio80 from '../../../assets/sounds/child/se/wordComprehension/irriterad.mp3';

import audio81 from '../../../assets/sounds/child/se/wordComprehension/dolja.mp3';
import audio82 from '../../../assets/sounds/child/se/wordComprehension/fiska.mp3';
import audio83 from '../../../assets/sounds/child/se/wordComprehension/gomma.mp3';
import audio84 from '../../../assets/sounds/child/se/wordComprehension/kasta.mp3';
import audio85 from '../../../assets/sounds/child/se/wordComprehension/smorja.mp3';

import audio86 from '../../../assets/sounds/child/se/wordComprehension/pasta.mp3';
import audio87 from '../../../assets/sounds/child/se/wordComprehension/havda.mp3';
import audio88 from '../../../assets/sounds/child/se/wordComprehension/viska.mp3';
import audio89 from '../../../assets/sounds/child/se/wordComprehension/sta-upp.mp3';
import audio90 from '../../../assets/sounds/child/se/wordComprehension/engagera-sig.mp3';

const audioFilesObj = [
	{
		mainWord: audio1,
		potentialSynonyms: [
			{synonym: audio2},
			{synonym: audio3},
			{synonym: audio4},
			{synonym: audio5},
		],
	},
	{
		mainWord: audio6,
		potentialSynonyms: [
			{synonym: audio7},
			{synonym: audio8},
			{synonym: audio9},
			{synonym: audio10},
		],
	},
	{
		mainWord: audio11,
		potentialSynonyms: [
			{synonym: audio12},
			{synonym: audio13},
			{synonym: audio14},
			{synonym: audio15},
		],
	},
	{
		mainWord: audio16,
		potentialSynonyms: [
			{synonym: audio17},
			{synonym: audio18},
			{synonym: audio19},
			{synonym: audio20},
		],
	},
	{
		mainWord: audio21,
		potentialSynonyms: [
			{synonym: audio22},
			{synonym: audio23},
			{synonym: audio24},
			{synonym: audio25},
		],
	},
	{
		mainWord: audio26,
		potentialSynonyms: [
			{synonym: audio27},
			{synonym: audio28},
			{synonym: audio29},
			{synonym: audio30},
		],
	},
	{
		mainWord: audio31,
		potentialSynonyms: [
			{synonym: audio32},
			{synonym: audio33},
			{synonym: audio34},
			{synonym: audio35},
		],
	},
	{
		mainWord: audio36,
		potentialSynonyms: [
			{synonym: audio37},
			{synonym: audio38},
			{synonym: audio39},
			{synonym: audio40},
		],
	},
	{
		mainWord: audio41,
		potentialSynonyms: [
			{synonym: audio42},
			{synonym: audio43},
			{synonym: audio44},
			{synonym: audio45},
		],
	},
	{
		mainWord: audio46,
		potentialSynonyms: [
			{synonym: audio47},
			{synonym: audio48},
			{synonym: audio49},
			{synonym: audio50},
		],
	},
	{
		mainWord: audio51,
		potentialSynonyms: [
			{synonym: audio52},
			{synonym: audio53},
			{synonym: audio54},
			{synonym: audio55},
		],
	},
	{
		mainWord: audio56,
		potentialSynonyms: [
			{synonym: audio57},
			{synonym: audio58},
			{synonym: audio59},
			{synonym: audio60},
		],
	},
	{
		mainWord: audio61,
		potentialSynonyms: [
			{synonym: audio62},
			{synonym: audio63},
			{synonym: audio64},
			{synonym: audio65},
		],
	},
	{
		mainWord: audio66,
		potentialSynonyms: [
			{synonym: audio67},
			{synonym: audio68},
			{synonym: audio69},
			{synonym: audio70},
		],
	},
	{
		mainWord: audio71,
		potentialSynonyms: [
			{synonym: audio72},
			{synonym: audio73},
			{synonym: audio74},
			{synonym: audio75},
		],
	},
	{
		mainWord: audio76,
		potentialSynonyms: [
			{synonym: audio77},
			{synonym: audio78},
			{synonym: audio79},
			{synonym: audio80},
		],
	},
	{
		mainWord: audio81,
		potentialSynonyms: [
			{synonym: audio82},
			{synonym: audio83},
			{synonym: audio84},
			{synonym: audio85},
		],
	},
	{
		mainWord: audio86,
		potentialSynonyms: [
			{synonym: audio87},
			{synonym: audio88},
			{synonym: audio89},
			{synonym: audio90},
		],
	},
];

export default audioFilesObj;
