import audio1 from '../../../../assets/sounds/child/se/diktat/SWSCRC6-30.mp3';
import audio2 from '../../../../assets/sounds/child/se/diktat/SWSCRC6-31.mp3';
import audio3 from '../../../../assets/sounds/child/se/diktat/SWSCRC6-32.mp3';
import audio4 from '../../../../assets/sounds/child/se/diktat/SWSCRC6-33.mp3';
import audio5 from '../../../../assets/sounds/child/se/diktat/SWSCRC6-34.mp3';
import audio6 from '../../../../assets/sounds/child/se/diktat/SWSCRC6-35.mp3';
import audio7 from '../../../../assets/sounds/child/se/diktat/SWSCRC6-36.mp3';
import audio8 from '../../../../assets/sounds/child/se/diktat/SWSCRC6-37.mp3';
import audio9 from '../../../../assets/sounds/child/se/diktat/SWSCRC6-38.mp3';
import audio10 from '../../../../assets/sounds/child/se/diktat/SWSCRC6-39.mp3';
import audio11 from '../../../../assets/sounds/child/se/diktat/SWSCRC6-40.mp3';
import audio12 from '../../../../assets/sounds/child/se/diktat/SWSCRC6-41.mp3';
import audio13 from '../../../../assets/sounds/child/se/diktat/SWSCRC6-42.mp3';
import audio14 from '../../../../assets/sounds/child/se/diktat/SWSCRC6-43.mp3';
import audio15 from '../../../../assets/sounds/child/se/diktat/SWSCRC6-44.mp3';
import audio16 from '../../../../assets/sounds/child/se/diktat/SWSCRC6-45.mp3';
import audio17 from '../../../../assets/sounds/child/se/diktat/SWSCRC6-46.mp3';
import audio18 from '../../../../assets/sounds/child/se/diktat/SWSCRC6-48.mp3';
import audio19 from '../../../../assets/sounds/child/se/diktat/SWSCRC6-49.mp3';
import audio20 from '../../../../assets/sounds/child/se/diktat/SWSCRC6-50.mp3';
import audio21 from '../../../../assets/sounds/child/se/diktat/SWSCRC6-51.mp3';
import audio22 from '../../../../assets/sounds/child/se/diktat/SWSCRC6-52.mp3';
import audio23 from '../../../../assets/sounds/child/se/diktat/SWSCRC6-53.mp3';
import audio24 from '../../../../assets/sounds/child/se/diktat/SWSCRC6-54.mp3';
import audio25 from '../../../../assets/sounds/child/se/diktat/SWSCRC6-55.mp3';


const audioFiles = {
	1: audio1,
	2: audio2,
	3: audio3,
	4: audio4,
	5: audio5,
	6: audio6,
	7: audio7,
	8: audio8,
	9: audio9,
	10: audio10,
	11: audio11,
	12: audio12,
	13: audio13,
	14: audio14,
	15: audio15,
	16: audio16,
	17: audio17,
	18: audio18,
	19: audio19,
	20: audio20,
	21: audio21,
	22: audio22,
	23: audio23,
	24: audio24,
    25: audio25,


};

export default audioFiles;
